.login-page .form-group span {
    position: absolute;
    top: 13px;
    left: 25px;
}

.login-page .form-group label {
    position: absolute;
    top: 13px;
    right: 19px;
    cursor: pointer;
}
.login-page-left::before {
    position: absolute;
    background: #e8953247;
    left: 0;
    top: 0;
    width: 100%;
    content: "";
    height: 100%;
} .login-page-left img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.login-page-left {
    height: 100vh;
    /* background-image: url(../../../assets/images/loginleft.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    position: relative;
}
.login-page {
    background-image: url(../../../assets/images/Login.png);
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 100vh;
}
.login-page-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    background: rgba(225, 225, 225, 0.2);
    padding: 80px 50px;
    border-radius: 15px;
    border: 1px solid #fff;
}
.login-page-right h5 {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
    color: #fff;
    font-family: Glitten;
}
.login-page .form-group img {
    max-width: 20px;
}
.login-page-right p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #fff;
}


.login-page-right form input {
    background: #e8f0fe;
    border-radius: 11.4826px;
    border: 0;
    width: 100%;
    height: 55px;
    padding: 0 20px;
}

.login-page-right form {
    padding-top: 10px;
    width: 100%;
    float: left;
}

.login-page .form-group {
    position: relative;
    margin-bottom: 16px;
}

.login-page .form-group a {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    text-decoration: none;
}

.login-page .form-group button {
    background: #e89532;
    border-radius: 11.4826px;
    border: 0;
    width: 100%;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    margin-top: 28px;
    padding: 14px 0;
}
.copy-right-lo {
    position: relative;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    color: #fff;
    padding-top: 200px;
}


.copy-right-lo p {
    font-size: 13px !important;
}

.copy-right-lo ul {
    padding: 0;
    margin-left: auto;
    display: flex;
}
.copy-right-lo ul li {
    margin-left: 10px;
    list-style: none;
}

.copy-right-lo ul li a {
    font-size: 13px;
    color: #e89531;
    opacity: 1;
    text-decoration: none;
}
@media (max-width:1400px)
{
    .copy-right-lo p {
        font-size: 11px !important;
    }
    .copy-right-lo ul li a {
        font-size: 11px;
    }
}
@media (max-width:991px) {
    .login-page::before {
        opacity: 0;
    }
    .login-page-right form {
        margin: 0 auto;
    }
}
@media only screen and (min-width: 100px) and (max-width: 800px) {
    .copy-right-lo {padding-top: 30px;}
    .login-page-left { height: 100%;}
    .login-page-right {padding: 20px; background: rgba(225, 225, 225, 0.3);}
}
@media only screen and (min-width: 100px) and (max-width: 500px) {
    .copy-right-lo {flex-direction: column;}
    .copy-right-lo ul {margin-left: 0;}
    .LoginHeading {margin-bottom: 0;}
}

@media only screen and (min-width: 800px) and (max-width: 1600px) {
    .copy-right-lo {padding-top: 50px;}
    .login-page-right {padding: 50px 40px;}
}