.heading {
    font-size: 20px;
    font-weight: 600;
}

.addPromotionCard {
    box-shadow: 0px 0px 12px 1px rgba(232,223,232,1);
    border-radius: 7px;
}

.promo-form input,
.promo-form textarea {
    width: 100%;
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 12px;
}

.promo-form .form-group {
    margin-bottom: 17px;
}

.promo-form button[type=submit] {
    background: #e89532;
    border: 0;
    color: #fff;
    padding: 10px 40px;
    border-radius: 4px;
}

form.promo-form textarea {
    height: 100px;
}