.propertyFeature {
    /* border: 1px solid #5f5f5f; */
    /* padding: 14px; */
    /* border-radius: 10px; */
    height: auto;
    /* box-shadow: 0 0 10px 1px #adadad96; */
}

.propertyFeature ul {
    padding: 0;
}

.propertyFeature ul li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-right: 20px;
    font-size: 14px;
    justify-content: space-between;
}

.main-room-slider .slick-list {
    padding: 11px 10px 10px 10px;
}

.main-room-slider.cursor-pointer .slick-track {
    padding: 7px;
}

.main-room-slider .slick-slide {
    padding-right: 16px;
}

.main-room-slider .slick-list {
    padding: 10px 10px;
}

.propertyFeature ul li span {
    display: flex;
}

.propertyFeature ul li div {
    display: flex;
    align-items: center;
}

.propertyFeature ul li li span {
    display: flex;
    align-items: center;
    margin-left: auto;
}


.propertyFeature img {
    max-width: 21px;
    margin-right: 10px;
    border-radius: 4px;
    max-height: 29px;
    object-fit: contain;
}

.modal-header {
    border: 0;
    text-align: center;
}

.modal-header h5.modal-title {
    margin: 0 auto;
    display: table;
    width: 100%;
}

.modal-header button {
    position: absolute;
    left: 18px;
    color: #000;
    opacity: 1;
}

.amenitiesCheckbox {
    position: relative;
    appearance: none;
    width: 100px;
    height: 50px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
}

.bedIcon {
    width: 40px;
    height: 40px;
}

.propertyFeature p {
    font-size: 14px;
}

.iconCount {
    position: fixed;

}

.modal-header {
    border: 0;
}

.modal-header h5.modal-title {
    margin: 0 auto;
    display: table;
    width: 100%;
    text-align: center;
}

ul.slick-dots {
    padding-right: 15px;
}

.modal-header button {
    position: absolute;
    opacity: 1;
}

.room {
    max-width: 60px !important;
    height: 45px;
}

.recommendedProperty {
    position: relative;
}

.recommendedProperty img {
    height: 240px;
    object-fit: cover;
    padding: 0px 0px;
    width: 100%;
    border-radius: 15px;
}


.gallery-modal .col-md-2 img {
    width: 100%;
    height: 160px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.gallery-count {
    position: absolute;
    top: 0;
    right: 19px;
    background: #e89532;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    cursor: pointer;
}

.slide-name h4 {
    color: #4e4439 !important;
    font-family: Cambay-Regular, sans-serif !important;
    font-size: 90%;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 5px;
}


.recommendedProperty p {
    position: absolute;
    top: 7px;
    right: 19px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    opacity: 0.5;
    cursor: pointer;
    background: #e89532;
}

.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4e4439;
    font-size: 16px;
    margin-bottom: 0;
    position: relative;
}

.amnites li:before {
    content: "";
    position: absolute;
    left: -21px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #4e4439;
}

.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4e4439;
    font-size: 16px;
    margin-bottom: 0;
    position: relative;
}

.aminity-wh-img {
    margin-bottom: 30px;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    grid-gap: 20px 40px;
    background: #f7f7f7;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 20px;
    position: relative;
    /* width: -webkit-max-content;
    width: max-content; */
}

.aminity-wh-img li {
    color: #333;
    font-family: Campton-light;
    font-weight: 700;
    margin: 0;
    position: relative;
    grid-gap: 10px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    white-space: nowrap;
    white-space: break-spaces;
    width: 22%;
    font-size: 15px;
    line-height: 1.2;
}

.amnites li img {
    margin-right: 0px;
    max-width: 25px;
}

.other-room {
    grid-template-columns: 1fr 1fr 1fr;
}

.amnites li:first-child::before {
    display: none;
}

.aminity-wh-img li::before {
    display: none;
}


.special-amnitiy {
    color: #e89532 !important;
}

@media (max-width:767px) {
    .aminity-wh-img {
        display: flex;
        width: auto;
    }
    .side-sider
    {
        display: none !important;
    }
    .propertyFeature {
        height: auto;
        width: 100% !important;
    }
    .propertyFeature ul li {
        padding-right: 0;
        font-size: 13px;
        padding-bottom: 8px;
    }
    .propertyFeature {
        padding: 0;
        box-shadow: none;
    }
    .propertyFeature img
    {
        margin-right: 12px !important;
    }
    .aminity-wh-img li {

        width: 100%;
        font-size: 14px;
    }

    .aminity-wh-img li img {
        height: 39px;
        object-fit: contain;
    }

    .aminity-wh-img {
        grid-gap: 3px 12px;
        padding: 0;
        background: transparent;

    }
    .aminity-wh-img li {
        width: 100%;
    }
}

/* Gallery Modal Styles */
.galleryModal :global(.ant-modal-content) {
  background-color: black !important;
  height: 100vh;
  border-radius: 0;              
}

.galleryModal :global(.ant-modal) {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
  height: 100vh;
  overflow: hidden;
}

.galleryModal :global(.ant-modal-header) {
  background-color: black;
  border-bottom: none;
}

.galleryModal :global(.ant-modal-body) {
  background-color: black;
}

.galleryModal :global(.ant-modal-title) {
  color: white !important;
}

.galleryModal :global(.ant-modal-close),
.galleryModal :global(.ant-modal-close-x) {
  color: white !important;
}

.galleryModal :global(.slick-next:before),
.galleryModal :global(.slick-prev:before) {
  color: white !important;
  background-color: #FF8C00;
  font-size: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 26px;
  position: absolute;
}

.galleryMainImage img {
  max-height: 75vh;
  object-fit: contain;
}

.thumbnailContainer {
  padding: 0 30px;
  overflow: hidden;
}

.thumbnailActive {
  border: 2px solid #FF8C00;
}

.thumbnailInactive {
  border: 1px solid #444;
}

/* Property Image Styles */
.propertyMainImage {
  height: 70vh;
  object-fit: cover;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.propertyGridImage {
  height: calc(35vh - 2px);
  object-fit: cover;
}

.propertyGridImageTop {
  height: calc(23.33vh - 4px);
  object-fit: cover;
  border-top-right-radius: 7px;
}

.propertyGridImageMiddle {
  height: calc(23.33vh - 4px);
  object-fit: cover;
}

.propertyGridImageBottom {
  height: calc(23.33vh);
  object-fit: cover;
  border-bottom-right-radius: 7px;
}

/* Mobile Slider Styles */
.mobileSlideItem img {
  height: 40vh;
  object-fit: cover;
}

.mobilePropertySlider {
  padding: 0;
  margin-top: 0;
}

.mobilePropertySlider :global(.slick-slider) {
  margin: 0;
}

.mobilePropertySlider :global(.slick-slide) {
  padding: 0;
}

.mobilePropertySlider :global(.slick-dots) {
  bottom: 15px;
  z-index: 5;
}

.mobilePropertySlider :global(.slick-dots li button:before) {
  color: white;
  opacity: 0.8;
  font-size: 10px;
}

.mobilePropertySlider :global(.slick-dots li.slick-active button:before) {
  color: white;
  opacity: 1;
}

/* Room Card Styles */
.roomCard {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dee2e6;
}

.disableCard {
  opacity: 0.7;
}

.roomImage {
  height: 180px;
  object-fit: cover;
}

.roomCardTitle {
  font-weight: 500;
  font-size: 17px;
}

.roomStatusSwitch:checked {
  background-color: #FF8C00;
  border-color: #FF8C00;
}

.roomMetricBox {
  background: #E894321A;
  border: 3px solid white;
}

.roomMetricLabel {
  color: #777;
  font-size: 10px;
  text-transform: uppercase;
  height: 25px;
  line-height: 1.2;
}

.roomMetricValue {
  font-size: 16px;
  font-weight: 500;
  margin-top: 3px;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .galleryMainImage img {
    max-height: 60vh;
  }
  
  .thumbnailContainer img {
    height: 110px !important;
    width: 110px !important;
  }
}

@media (max-width: 768px) {
  .galleryMainImage img {
    max-height: 68vh;
  }
  
  .mobilePropertySlider {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
  
  .mobileSlideItem img {
    height: 50vh !important;
    width: 100%;
    border-radius: 0;
  }
}

@media (max-width: 576px) {
  .galleryMainImage img {
    max-height: 70vh;
  }
  
  .thumbnailContainer img {
    height: 100px !important;
    width: 100px !important;
  }
}

@media (max-width: 375px) {
  .galleryMainImage img {
    max-height: 50vh;
  }
  
  .thumbnailContainer img {
    height: 60px !important;
    width: 60px !important;
  }
}

@media (max-width: 320px) {
  .galleryMainImage img {
    max-height: 40vh;
  }
  
  .thumbnailContainer img {
    height: 100px !important;
    width: 100px !important;
  }
}