.about h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;
    text-transform: capitalize;
}

.about h2 a {
    background: #e89532;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 6px 20px;
    border-radius: 5px;
}

.about h2 a i {
    margin-right: 5px;
}
.about {background: #fff; padding: 20px; border-radius: 15px;border: 1px solid #ccc;}
.about p {font-size: 14px; line-height: 24px;}