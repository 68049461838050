@media (max-width:1400px) {
    .inner-tabs h5 {
        font-size: 16px;
        max-width: 160px;
    }
    .pie-chart-left h3,
    .pie-chart-right h3 {
        font-size: 16px;
    }
    body h3 {
        font-size: 16px;
    }
    .card-shadow {
        padding: 14px;
    }
    .webadd-btn {
        margin-left: 8px;
        position: relative;
    }
}

@media (max-width:991px) {
    .main-dashboard .col-md-7 {
        width: 100%;
    }
    .main-dashboard .col-md-5 {
        width: 100%;
    }
    .pie-chart .col-md-4,
    .table-chart .col-md-4 {
        width: 100%;
        margin-bottom: 25px;
    }
    .table-chart {
        padding-top: 15px;
    }
    .left-top-bar h4 {
        margin-left: 6px;
        font-size: 15px;
    }
    .add-property-sc .form-group.add-image.new-add-gallery span {
        top: 6px;
        margin-bottom: 32px;
    }
    .add-property-sc .form-group.add-image.new-add-gallery input {

        top: 33px;
    }
    .table-chart-right.card-shadow table,
    .table-chart-left.card-shadow table {
        width: 100%;
    }
}

@media (max-width:767px) {

    .pro-detail-mg,
    .check-date,
    .due-date {
        padding: 20px 14px 16px;
    }
    .special, .common {
        min-width: 20px;
        border-radius: 3px;
    }
    .w-25.d-flex.align-items-center {
        width: 100% !important;
    }
    .mob-common {
        flex-wrap: wrap;
        row-gap: 16px;
    }
    .mob-common p {
       font-size: 15px;
       padding-left: 4px;
    }
    .mobSlider .slick-list {
        padding: 0 !important;
    }
    .mobSlider .slick-track {
        display: flex;
    }
    button.add-mob-btn {
        display: block;
    }
    .modal-header h5 {
        font-size: 17px;
    }
    div#calendar-price.show .modal-dialog.modal-dialog-centered {
        
        transform: translateY(0%) !important;
        top: initial;
        bottom: 0;
        padding: 20px;
    }
    div#calendar-price .modal-body {
        max-height: inherit;
        overflow-y: auto;
        height: 500px;
    }
    .dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content {
        min-width: 100%;
    }
    div#calendar-price .modal-dialog.modal-dialog-centered {
        position: fixed;
        right: 0;
        margin: 0;
        display: table;
        transform: translateY(381%) !important;
        width: 100%;
        top: initial;
        left: 0;
        margin: 0 auto;
        height: auto;
        transition: 0.7s !important;
        min-height: inherit;
        bottom: 0;
        transform: translate(0) !important;
    }
    .no-margin {
        margin: 0 !important;
    }
    body .mobie-detail h4 {
        display: flex;
        align-items: flex-start;
        font-size: 16px;
        color: #1C2D34;
        font-family: Campton-light;
        margin: 0;
    }
    body .mobie-detail {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px 1px #ddd;
        padding: 17px 13px;
        margin: 17px 0;
    }
    body .mobie-detail .mt-4.mb-2 {
        margin-top: 5px !important;
    }
    .mobie-detail h6 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .mobie-detail ul.slick-dots {
        display: none !important;
    }
    .mobie-detail p, .mobie-detail ul li {
        font-size: 14px;
    }
    .mobie-detail  img {
        margin: 0 !important;
    }
    body .mobie-detail h5 {
        font-size: 14px;
        color: #1C2D34;
        font-family: Campton-light;
        margin: 0;
        font-weight: 400;
    }
    body .mobie-detail h4 {
        display: flex;
        align-items: flex-start;
    }
    .mobile-slide-custom .slick-track {
        display: flex;
        flex-direction: initial;
        column-gap: 10px;
    }
    .mobile-search {
        width: 100%;
    }
    .propertyType {
        position: relative;
        padding-left: 8px;
        width: 42%;
    }
    .FilterMob {
        width: 13%;
        text-align: right;
        padding-top: 11px;
    }
    body .propertyType select {
        padding: 0 16px 0 10px;
    font-size: 14px;
    appearance: none;
    }
    .propertyType span {
        position: absolute;
        right: 8px;
        top: 10px;
    }
    img.show-web {
        display: none;
    }
    img.show-mob {
        display: initial;
    }
    .webadd-btn
    {
        display: none;
    }
    button.add-mob-btn {
        background: #e89534;
        color: white;
        border: 0;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 92px;
        right: 20px;
        z-index: 28;
    }
    .filterMobDropdown {
        position: absolute;
        right: 0;
        background: #fff;
        z-index: 9;
        padding: 12px;
        top: 54px;
    }
    .filterMobDropdown .form-group {
        margin-bottom: 4px;
    }
    .mobFilter {
        display: block;
    }
    body ul.paginaion {
        display: none;
    }
    img.desk-ic {
        display: none;
    }
 .propertyType, .FilterMob{
        display: none  !important;
    }
    .w-75 {
        width: 99%!important;
    }
    span.toggler img {
        display: block;
    }
    img.mob-ic {
        display: block;
    }
    .mobile-edit {
        display: block;
    }
    .mobile-edit img {
        max-width: 22px;
        height: auto !important;
        width: 18px !important;
        height: 18px !important;
    }
    .mobile-edit div {
        min-height: auto;
        position: static;
    }
    .mobile-edit {
        position: absolute;
        top: -9px;
        right: 3px;
    }
    span.toggler i {
        display: none;
    }
    span.mobileHide {
        display: block;
    }
    .side-left img {
        height: 220px;
    }

    .side-left {
        padding-right: 9px;
    }
    .main-sider .slick-slide.slick-active.slick-center.slick-current {
        transform: scale(1.4);
        z-index: 9;
    }
    .main-sider .slick-list {
        padding: 0px 0 20px !important;
    }
    .main-sider .slick-slide::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        background: #0000002e;
        border-radius: 13px;
    }
    .main-sider .slick-slide.slick-active.slick-center.slick-current::before
    {
        opacity: 0;
    }
    .main-sider .slick-slide
    {
        position: relative;
    }
    .side-sider {
        padding-left: 0;
    }

    .side-sider {
        width: 20%;
        padding-left: 0;
    }

    .inner-tab-deisign .bed-rooms ul {
        flex-wrap: wrap;
    }

    .side-sider img {
        margin-bottom: 0px;
        height: 68px;
    }

    .inner-tab-deisign .bed-rooms ul li img {
        max-width: 23px;
        opacity: 0.8;
        margin-right: 0;
        max-height: 20px;
        object-fit: contain;
        border-right: 0;
        padding-right: 0;
        min-height: 22px;
        margin-bottom: 2px;
    }

    ul.car-amnites.aminity-wh-img li {

        width: 100%;
        font-size: 14px;
    }

    .inner-tab-deisign .bed-rooms ul li {
        margin: 0 5px 0 0;
        font-size: 13px;
        width: 31.5%;
        padding: 9px 5px;
        flex-direction: column;
    }

    div#map-show iframe {
        height: 340px;
    }

    .filter-pro input {
        width: 100%;
        margin: 0 0 10px !important;
        min-width: initial;
    }

    .filter-pro select {
        width: 100%;
        margin: 0 0 9px !important;
    }
    .gray-clr {
        padding: 8px 15px;
        background: #FFECD5;
    }

    .gray-clr p {
        font-size: 15px;
    }

    .pie-chart .col-md-4,
    .table-chart .col-md-4 {
        margin-bottom: 2px;
    }

    .card-shadow {
        margin: 10px 0;
    }

    .proerty-tabs.mt-4 {
        margin: 0 !important;
    }

    .table-chart-right.card-shadow {
        height: auto;
    }

    .pie-chart {
        padding-top: 11px;
    }

   
    ul.paginaion li::before {
        left: -13px;
        top: 9px;
    }

    ul.paginaion li a {
        font-size: 13px;
    }

    .bathroom-select h5 {
        font-size: 16px;
    }

    .add-property-sc .form-group label {
        font-size: 14px;
    }

    span.sech-btn {
        border-radius: 100px;
        width: 100%;
        margin: 7px 0 13px;
    }

    .inner-tabs h5 {
        max-width: 100%;
    }

    .table-chart .listing-table-scrl {
        height: auto;
    }

    .notification {
        right: -30px;
        min-width: 360px;
    }

    .chat-information {
        font-size: 14px;
    }

    .filter-pro {
        flex-wrap: wrap;
    }
    .mobile-slider ul.slick-dots {
        position: absolute;
        bottom: 20px;
    }
    .mobile-slider ul.slick-dots {
        display: block;
     }
     .mobile-slider ul.slick-dots li {
        list-style: none;
        margin: 0 6px;
        width: auto;
    }
    .mobile-slider ul.slick-dots li button::before
    {
        display: none;
    }
    .mobile-slider ul.slick-dots li.slick-active button {
        background: #e89534;
        width: 15px;
        height: 15px;
    }
    span.toggler {display: none;}

}
