.filter-tab {
    display: flex;
    padding: 0;
    margin: 50px 0 0px;
    gap: 10px;
}

ul.filter-tab li {
    list-style: none;
    margin-right: 0 !important;
}

.filter-tab li span.active {
    color: #fff;
    background: #e89532;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #e89532;
}

.filter-tab li span.inactive {
    color: #e89532;
    background: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #e89532;
}
