.tabPanel {
    display: flex;
    background-color: #f9f7f7;
    border-radius: 5px;
    margin-bottom: 20px;
    max-width: 500px;
}
.tab {
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e89532;
}
.tab:hover
{
    background: #e89532;
    color: #fff;
}
.bookingNo {
    cursor: pointer;
    color: #E89532;
}

.activeTab {
    background-color: #E89532;
    border-bottom: 1px solid #E89532;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
}

.generateInvoice {
    margin-left: auto;
    float: right;
    background: #e89532;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    padding: 11px 20px;
    margin-right: 4px;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
    border: 0px;
}
.generateInvoice:hover
{
    background: #000;
}

.totalAmount {
    font-weight: 700;
}

.totalAmountTab {
    background-color: #f9f7f7;
}
.generateInvoicedata {
    margin-left: auto;
    float: right;
    font-size: 16px;
    text-decoration: none;
    padding: 11px 20px;
    margin-right: 4px;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
    border: 0px;
    background: #fff;
    border: 1px solid #e89532;
    color: #e89532;
}
.generateInvoicedata:hover
{
    color: #fff;
    background: #e89532;
}
@media (max-width:767px)
{
    .generateInvoicedata {
        font-size: 15px;
        padding: 9px 10px;
    }
    .generateInvoice {
        font-size: 15px;
        padding: 9px 10px;
    }
}