.acceptPayment {
    background-color: #E89532;
    outline: none;
    border: none;
    color: white;
    padding: 8px 15px;
    border-radius: 4px;

    cursor: pointer;
}

.vat {
    color: green;
}

.discount {
    color: red;
}

.check-date {
    padding: 20px 30px 16px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* padding-right: 90px; */
}

.check-date::before {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 70%;
    content: "";
    top: 50%;
    background: #ddd;
}

.check-date span {
    font-size: 13px;
    margin: 0;
}

.check-date h5 {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

.check-date h6 {
    font-size: 12px;
    color: #818181;
    margin: 4px 0 0;
}

.left-pro-det {
    width: 77%;
}

.left-pro-det h6 {
    color: #3d89ff;
    font-size: 14px;
}

.left-pro-det h5 {
    font-size: 16px;
    margin: 0;
}

.left-pro-det p {
    margin: 0;
    font-size: 13px;
}

.due-date {
    padding: 20px 30px 16px;
    border-bottom: 1px solid #ddd;
}

.due-date ul {
    padding: 0;
    margin: 0;
}

.due-date ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    padding: 2px 0;
}

.pro-detail-mg {
    border-bottom: 1px solid #ddd;
    padding: 20px 30px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-pro-del img {
    max-width: 50px;
    border-radius: 100%;
    object-fit: cover;
    max-height: 50px;
    min-height: 50px;
}

.chat-otr {
    background: #fff;
    border: 1px solid #ddd;
    padding: 17px 0;
}

.proety-top {
    margin-bottom: 26px;
}

.detail-box {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 15px;
}

.reservation-message-box {
    position: relative !important;
    height: calc(100vh - 370px) !important;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.senderImage {
    width: 45px;
    height: 40px;
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 8px;
}

.message {
    font-size: 16px;
    background: #fff;
    outline: none;
}