.scroll-calander {
    display: flex;
    overflow-x: auto;
}
a.canvasjs-chart-credit {
    display: none !important;
}
.form-switch .form-check-input {
    background-color: #dddddd;
}

.form-switch .form-check-input:checked {
    background-color: #e89532;
    border-color: #e89532;
}
.mobile-edit {
    display: none;
}
.w-75 {
    width: 99%!important;
}
.outer-calander {
    width: 100%;
    min-width: 100%;
    padding-right: 26px;
}

select {
    appearance: none;
}

.togglr-btn span {
    cursor: pointer;
}

.main-sider {
    display: flex;
    flex-wrap: wrap;
    /* align-items: flex-end; */
    margin: 15px 0 20px;
}

.more-hotos {
    position: absolute;
    bottom: 20px;
    background: #000000bf;
    width: 95%;
    min-height: 138px;
    border-radius: 10px;
    left: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-hotos p {
    margin: 0;
}

th.answr {
    width: 750px;
}

ul.paginaion li {
    margin: 0 33px 0 0;
    position: relative;
}

ul.paginaion li a {
    color: #8e8e8e;
    text-decoration: none;
}

ul.paginaion li a:hover {
    color: #e89532;
}

ul.paginaion li::before {
    position: absolute;
    left: -17px;
    top: 7px;
    width: 8px;
    height: 8px;
    border: 1px solid #c6c6c6;
    content: "";
    border-top: 0;
    border-left: 0;
    transform: rotate(-45deg);
}

ul.paginaion li:first-child::before {
    opacity: 0;
}

/* .modal.fade {
    opacity: 1 !important;
    visibility: visible !important;
} */

ul.paginaion li.active a {
    color: #E89532;
    font-weight: 600;
}

.edit-users-sc {
    box-shadow: 0 0 10px 1px #ddd;
    padding: 40px;
    max-width: 550px;
    margin: 0 auto;
    border-radius: 11px;
}

.edit-users-sc input,
.edit-users-sc select {
    width: 100%;
    height: 46px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 5px;
}

.edit-users-sc .form-group {
    margin-bottom: 15px;
}

.edit-users-sc .form-group label {
    margin-bottom: 3px;
    width: 100%;
}

.edit-users-sc .form-group input[type="radio"] {
    width: auto;
    height: auto;
}

.verified {
    margin-bottom: 6px;
    color: #797979;
}

.edit-users-sc button {
    width: 100%;
    height: 42px;
    border: 0;
    color: #fff;
    background: #e89532;
    font-size: 19px;
    font-weight: 600;
    border-radius: 5px;
}

.name.d-flex {
    align-items: center;
}

h5.loc {
    margin: 8px 0 19px;
    color: #4e4439;
    font-size: 110%;
    font-weight: 600;
    font-family: Campton-medium !important;
    align-items: center;
}

.pac-container {
    z-index: 9999;
}

input.pac-target-input {
    width: 100%;
}

h5.loc img {
    margin-right: 10px;
}

.location {
    border: 1px solid #ddd;
    border-radius: 100px;
    padding: 15px 13px;
    font-size: 12px;
}

.location i {
    color: #fa4d28;
    margin-right: 6px;
}

.card {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #fff;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
}

/* search screen start */

.rad {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rad input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

.rad:hover input~.checkmark {
    background-color: #ccc;
}

.rad input:checked~.checkmark {
    background-color: #e89532;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.rad input:checked~.checkmark:after {
    display: block;
}

.rad .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.special {
    width: 20px;
    height: 20px;
    background-color: #e89532;
}

.common {
    width: 20px;
    height: 20px;
    background-color: #353131;
}

.react-select__input {
    height: 32px !important;
}

.disabled-locations {
    color: red !important;
    font-size: 13px;
}

#calendar-price .rad .checkmark:after {
    top: 5px;
    left: 5px;
}

#calendar-price label.rad {
    font-size: 16px;
    margin-right: 30px;
    padding-left: 28px;
}

#calendar-price span.checkmark {
    height: 18px;
    width: 18px;
    top: 2px;
}

#calendar-price button.btn.btn-primary {
    background: #e89532;
    border: 0;
}

div#calendar-price p {
    margin: 8px 0;
}

div#calendar-price .modal-body {
    max-height: 500px;
    overflow-y: auto;
}

.react-datepicker-wrapper {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.disable {
    background: #efefef;
    cursor: not-allowed;
}

.dasboad-property-detail {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
}

.property-list-out {
    max-width: 100%;
    margin: 0 auto;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered {
    position: fixed;
    right: 0;
    margin: 0;
    display: table;
    transform: translateY(381%) !important;
    width: 95%;
    top: 50%;
    left: 0;
    margin: 0 auto;
    height: auto;
    transition: 0.7s !important;
    min-height: inherit;
}

.modal.show .modal-dialog {
    transition: 0.7s !important;
    top: 50%;
    transform: translateY(-50%) !important;
}

.modal.fade {
    z-index: -1;
    display: inherit !important;
}

.modal.fade.show {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
}

.modal.fade {
    transition: 0.7s !important;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content {
    border: 0;
    height: 100%;
    min-width: 921px;
    box-shadow: 0 0 10px 1px #ddd;
    height: auto;
    overflow-y: auto;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content .drop-image-outer span {
    background: #fff;
    position: relative;
    z-index: 9;
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    border-radius: 10px;
    justify-content: center;
    pointer-events: none;
    align-items: center;
    color: #e89532;
}


.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content .drop-image-outer {
    border: 1px dashed #ddd;
    border-radius: 7px;
    padding: 31px 0;
    position: relative;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content .drop-image-outer input {
    font-size: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 30px;
    top: 0;    
}

.addpadding input {
    padding-right: 80px;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content input {
    background: #fff;
}

.btnedits {
    position: absolute;
    top: 5px;
    right: 18px;
}

.dasboad-property-detail .modal-body .mb-3:last-child {
    border: 0;
}

.dasboad-property-detail .show {
    background: #00000042;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content select {
    width: 100%;
}

.modal-body label {
    font-size: 15px;
}

.dasboad-property-detail .modal-body .mb-3 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 22px;
}

.modal-body input {
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 13px;
    padding: 0 15px;
}

.w-md-editor-area {
    overflow: auto;
    border-radius: 5px;
}

.form-group.description .h-100.wmde-markdown-var.w-md-editor.w-md-editor-show-edit {
    width: 75vw;
    height: 35vh !important;
}

.dasboad-property-detail .modal-body {
    max-height: 500px;
    overflow-y: auto;
}

#chat3 .form-control {
    border-color: transparent;
    height: 46px;
    border: 1px solid #ddd;
}

.rounded.mb-3 {
    position: relative;
}

#chat3 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
}

span#search-addon {
    position: absolute;
    background: transparent;
    top: 10px;
    right: 10px;
    color: #a3a3a3;
}

.badge-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-left: 2.9rem;
    margin-top: -0.75rem;
}

.rounded-3.bg-primary {
    background: #ffdaae !important;
    color: #000 !important;
    max-width: 73%;
    margin-left: auto !important;
    padding: 12px 20px !important;
    border-radius: 6px 6px 0 6px !important;
    display: table;

}

.chat-information {
    font-size: 20px;
}

.loading-text {
    height: 78vh;
}

.loading-text h5 {
    color: #e89532;
    font-size: 25px;
}

.loc .fa-pencil-alt,
.name .fa-pencil-alt,
.description .fa-pencil-alt {
    font-size: 11px;
    border: 1px solid #ddd;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

body .wmde-markdown {
    background: transparent;
    color: #4d4d4d;
    font-family: "Poppins", sans-serif !important;
}

h5.loc img {
    height: 20px;
    position: relative;
}

.description h5 {
    font-weight: 500;
    position: relative;
    font-family: Campton-medium !important;
    font-size: 1rem;
    margin: 0;
}

.main-room-slider .slick-slide {
    padding-right: 16px;
}

.main-room-slider .slick-list {
    padding: 10px 10px;
}

/* Dashboard styles. Do not remove it  */

.inner-tabs h4 {
    color: #282828;
    font-weight: bold;
}
/* 
.pie-chart-left.card-shadow div,
.pie-chart-right.card-shadow div {
    height: 330px !important;
} */

.pie-chart-left h3,
.pie-chart-right h3 {
    font-size: 20px;
    margin-bottom: 18px;
    font-weight: 600;
    color: #4e4e4e;
}

.pie-chart {
    padding-top: 40px;
}

.pie-chart-left,
.pie-chart-right {
    position: relative;
    background-color: white;
}

.inner-tabs h5 {
    color: #2d2d2d;
}

.proerty-tabs .col-md-3:nth-child(2) .inner-tabs.card-shadow,
.proerty-tabs .col-md-3:nth-child(4) .inner-tabs.card-shadow {
    background: #FFECD5;
}

.proerty-tabs .col-md-4:nth-child(2) .inner-tabs.card-shadow,
.proerty-tabs .col-md-4:nth-child(4) .inner-tabs.card-shadow {
    background: #FFECD5;
}

.inner-tabs span {
    position: absolute;
    top: 50%;
    right: 21px;
    width: 60px;
    height: 60px;
    background: #e89532;
    color: #fff;
    border-radius: 100px;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    display: none;
}

/* 1439 */

/*-comon-css--*/
body {
    font-family: "Poppins", sans-serif !important;
    background: #f4f4f487;
}

* {
    outline: none;
}

body h3 {
    font-size: 18px;
    font-weight: 500;
    color: #4e4e4e;
}

.main-dashboard {
    display: flex;
    height: 100%;
    /* flex-wrap: wrap; */
}

.error {
    color: red;
    font-size: 9px;
}

canvas {
    width: 100%;
    height: 330px;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #e89532;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #e89532;
}

input:checked+.slider {
    background-color: #E89532;
}

input:focus+.slider {
    box-shadow: 0 0 1px #E89532;
}

input:checked+.slider:before {
    transform: translateX(15px);
}

.text-muted.d-flex.justify-content-start.align-items-center.pe-3.pt-3.mt-2 {
    padding: 0 20px !important;
}

select {
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 15px;
}

.form-group {
    margin-bottom: 15px;
    position: relative;
}

.card-shadow {
    padding: 30px;
    border-radius: 15px;
    display: block;
    width: 100%;
    background: #fff;
    /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); */
    border-top: 5px solid #e89533;
}

.proerty-tabs a {
    text-decoration: none;
}

div#calendar-price .modal-dialog.modal-dialog-centered {
    position: fixed;
    right: 0;
    margin: 0;
    display: table;
    width: 95%;
    top: 0%;
    left: 0;
    margin: 0 auto;
    height: auto;
    transition: 0.7s !important;
    min-height: inherit;
    transform: translateY(10%) !important;
}

div#calendar-price.show .modal-dialog.modal-dialog-centered {
    transition: 0.7s !important;    
}

.ImageEdit input {
    border: 0;
    font-size: 0;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ImageEdit span {
    width: 80px;
    height: 80px;
    background: #ddd;
    display: flex;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #a1a1a1;
    z-index: 1;
    position: relative;
    pointer-events: none;
}

.ImageEdit {
    width: 80px;
    height: 80px;
    position: relative;
}

.bsnkdetails ul {
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    border-bottom: 0;
}

.bsnkdetails ul li b {
    width: 162px;
}

.bsnkdetails ul li {
    list-style: none;
    border-bottom: 1px solid #ddd;
    padding: 11px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.ImageEdit img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    border-radius: 100px;
    object-fit: cover;
    pointer-events: none;
}

div#calendar-price .modal-body label {
    width: 100%;
}

div#calendar-price .modal-body .mb-3 {
    display: inline-block;
    width: 100%;
}

body .react-calendar {
    margin: 0 8px;
}

body .react-calendar__tile--active {
    background: #e89532 !important;
}

span.toggler img {
    display: none;
}

img.mob-ic,
.propertyType,
.FilterMob {
    display: none;
}

img.show-mob {
    display: none;
}

.mobile-slider ul.slick-dots {
    display: none;
}

button.add-mob-btn {
    display: none;
}
button.swal2-confirm.swal2-styled {
    background: #e89532;
}

.ImageEdit label {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25px !important;
    height: 25px;
    background: #e89532;
    border-radius: 100px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
}
.image-preview img {
    width: 100%;
    border-radius: 10px;
}
ul.propertTabing {
    padding: 0;
    margin: 10px 0 35px;
    display: flex;
    column-gap: 30px;
}
ul.propertTabing li {
    color: #7F7F7F;
    list-style: none;
    font-weight: 500;
    cursor: pointer;
}
ul.propertTabing li.active {
    color: #ED8536;
    font-weight: 600;    
}
.nextbtn button {
    float: right;
}
.form-check-input:checked {
    background-color: #e89432;
    border-color: #e89432;
}
.pagination ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    width: 100%;
    margin: 29px 0 20px;
}
.pagination ul li {
    list-style: none;
}
.pagination ul li span {
    background: #fff;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid #e89432;
    align-items: center;
    justify-content: center;
    color: #e89432;
}
.pagination ul li.active span {
    background: #e89432;
    
    border: 1px solid #e89432;
    color: #fff;
}
div#payloadmodal {
    z-index: 9;
    background: #00000036;
}
div#payloadmodal .modal-header {
    padding: 0;
    border: 0;
}
div#payloadmodal .modal-header button.btn-close {
    width: 30px;
    height: 30px;
    padding: 0;
    position: absolute;
    right: 21px;
    top: 23px;
    background-size: 14px;
    z-index: 9;
}
select {
    appearance: none !important;
    color: #1b1a1a;
}
/* .crop-container .reactEasyCrop_CropArea {
    width: 60% !important;
    height: 60% !important;
} */
.side-sider .slick-track {
    width: 100% !important;
    transform: translate(0) !important;
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: hidden;
}
/* .newOuterSlider .slick-slide div {
    height: 100%;
} */
.side-sider {
    width: 15%;
    float: left;
    padding-left: 15px;
    position: relative;
}

.side-sider .slick-track .slick-slide {
    width: 100% !important;
    min-height: auto;
    height: auto;
}
.side-sider .slick-track .slick-slide img {
    width: 100%;
    height: 90px;
    border-radius: 10px;
    object-fit: cover;
}
.catSlider h3 {
    font-size: 15px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 12px 20px;
    border-radius: 4px;
    margin-bottom: 0;
}
.catSlider h3.active {
    background: #E89532;
    color: #fff;
    border-color: #E89532;
}
.catSlider .slick-slide {
    padding: 0 4px;
    width: max-content !important;
    white-space: nowrap;
}
.catSlider .slick-track {display: flex;}
.catSlider
{
    padding: 0 30px 0 30px;
    position: relative;
    /* overflow-x: scroll; */
}
/* .catSlider::-webkit-scrollbar {width: 2px; height: 2px; border-radius: 30px;}
.catSlider::-webkit-scrollbar-track-piece {background: #f5f5f5;} */

.alsolike .slick-track {
    display: flex;
    gap: 20px;
}
.alsolike h4.LikeTitle {
    margin-bottom: 10px;
}
/* .catSlider_inner {
    display: flex;
    overflow-x: scroll;
    gap: 10px;
} */
/* .catSlider::before {
    width: 200px;
    height: 56px;
    background-image: linear-gradient(to right, #ffffff00, #fffffff3);
    content: "";
    position: absolute;
    right: 0;
    top: -5px;
    z-index: 9;
} */
.catSlider button.slick-arrow {
    display: block !important;
    position: absolute;
    left: -50px;
    width: 10px;
    height: 10px;
    top: 5px;
    z-index: 9;
}
.catSlider button.slick-arrow.slick-next {
    right: -20px;
    left: initial;
}
.catSlider button.slick-arrow::before {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    border: 2px solid #e89532;
    border-top: 0;
    border-right: 0;
    transform: rotate(45deg);
    top: 13px;
    left: 14px;
}



::-webkit-scrollbar {width: 5px; height: 5px; border-radius: 30px;}
/* ::-webkit-scrollbar-button {background: #e89531;} */
::-webkit-scrollbar-track-piece {background: #333;}
::-webkit-scrollbar-thumb {background: #e89531;} 

.CustomContainer{width: 100%; max-width: 600px; position: absolute; bottom: 40px; right: 100px;}
label.InputTitle {color: #fff; display: flex; justify-content: space-between; padding: 5px 0 15px;}
label.InputTitle a {color: #e89531; text-decoration: none;}
.LoginHeading {margin-bottom: 40px;}
.RentVip_Logo {margin-top: 30px;}
#ForgotPassword {background-image: url(../images/ForgetPassword.png); background-position: center;}

.mobile-slider a {text-decoration: none;}
.catSlider button.slick-arrow.slick-next::before {transform: rotate(-135deg); left: 11px;}
.button-prom button {padding: 8px 10px; height: auto; white-space: pre;}
/* 27-Feb-2025 */
*::selection {background-color: #e89532; color: #fff;}
.mobie-detail h4 {margin-bottom: 20px;}
.mobie-detail {margin: 20px 0;  border-bottom: 1px solid #ebebeb;}
.mobie-detail h6 {padding-bottom: 5px;font-weight: 600; font-size: 18px;}
.slide-name h4 { text-decoration: none;}
.ViewProperty .slick-track {display: flex; gap: 20px;}
.BoxDiv {box-shadow: 0 0 20px rgba(0,0,0,0.12); padding: 20px;border-radius: 15px; width: max-content; background: #fff;}
.ListStyleImg img {object-fit: cover; border: 1px solid #ccc;}
.action-btn {position: absolute;}
.inner-tabs {margin-bottom: 10px;}
.WelCome {position: relative; background: #e89533; border-radius: 10px; padding: 40px; margin-bottom: 30px; box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); border-top: 5px solid #e89533; text-align: center; font-size: 26px;}
.WelCome h2, .WelCome p {color: #fff; position: relative; z-index: 1;}
.WelCome p {font-size: 26px; margin-bottom: 0;}
.RecentProperties {height: 100%; min-height: 180px; background: #e89533; color: #fff; padding: 0; overflow: hidden;}
/* .RecentProperties img { width: 100%; mix-blend-mode: screen; position: absolute; height: 100%; top: 0; left: 0;} */
.RecentProperties img { width: 100%; height: 180px; object-fit: cover;}

.WelCome img {width: 100%; mix-blend-mode: lighten; position: absolute; height: 100%; top: 0; left: 0;}
.WelCome img.WelcomeImg {position: relative; height: auto;}
.RowGap {row-gap: 20px;}

.right-chat\=hd { position: relative; z-index: 11111;}
div#exampleModal { position: absolute; z-index: 1;}
.modal-dialog {position: absolute; right: 0; top: 90px;}
.pt-1 p {font-size: 14px;}
.NoPadding {padding: 0;}
.NoMargin {margin-right: 0;}

.Add_Edit_Delete {display: flex; align-items: center; gap: 10px;}
.Add_Edit_Delete span {margin: 0 !important;}

.side-sider .slick-next, .side-sider .slick-prev {background: #e89533 !important; border-radius: 50%; width: 30px; height: 30px;}
.slick-next:before, .slick-prev:before { font-size: 33px;}
.side-sider .slick-prev {left: 0; top: -10px; transform: rotate(90deg); z-index: 1; margin: 0 auto; right: 0;}
.side-sider .slick-next {left: 0; top: auto; bottom: 0; transform: rotate(90deg); z-index: 1; margin: 0 auto; right: 0;}

.mobFilter.form-group {margin-bottom: 5px; width: 100%;}
.mobile-search.form-group {width: 100%;}
.MobileFlex {gap: 20px;}
.login-form-logo img {max-width: 250px; margin: 0 auto;}
.login-form-logo {margin-bottom: 60px;}

.slick-prev {left: 20px;}
.slick-next {right: 20px;}
.slick-prev, .slick-next {z-index: 1;}

.slider-desc {background: #f5f5f5; border-radius: 15px; padding: 30px;}
.GapMobile {gap: 20px; justify-content: space-between;}
.css-tr4s17-option {background-color: #eed6b2;}
.css-t3ipsp-control {box-shadow: 0 0 0 0px #2684FF;}

.cursor-pointer button, .cursor-pointer a {color: #e89533;  text-decoration: none;}
.cursor-pointer button:hover {color: #333;}
.DashboardGraph {border-top: 5px solid #e89533; overflow: hidden; border-radius: 15px; padding: 10px; background: #fff;;}
.DashboardGraph #reactgooglegraph-1{ min-height: 400px;}
.Add_Property .modal-body {height: auto !important;}
/* @mediaScreen */
@media only screen and (min-width: 100px) and (max-width: 800px) {
    /* .side-sider {display: none;} */
    .view_product-slider-main .side-sider {width: 100%;}
    .row {row-gap: 15px;} 
    .catSlider .slick-slide {width: max-content !important;}
    .WelCome {margin: 30px 0 0px 0;}
    .button-prom {margin-top: 20px;}   
    .BoxDiv {width: 100%;}

    .MobileFlex {gap: 0px;}
    .MobileFlex {flex-direction: column;}
    .ficed-top-nbar {width: 100%;}

    .login-page-right {padding: 20px;}
    .GapMobile {gap: 0; flex-direction: column;}
    
    .property-list-out {padding: 20px 15px;}
    div.filter-pro {justify-content: flex-start !important;}
    .email {display: flex; align-items: center;}

    .CustomContainer {right: 0;}
    .left-pro-det h5 {font-size: 14px; margin: 0 0 5px; line-height: 20px;}
}
@media only screen and (min-width: 100px) and (max-width: 767px) {
.side-sider .slick-prev {left: 0; top: 0; right: auto; bottom: 0; transform: rotate(0deg); z-index: 1; margin: auto;}
.side-sider .slick-next {right: 0; left: auto; top: 0; bottom: 0; transform: rotate(0deg); z-index: 1; margin: auto;}
.side-sider {width: 100% !important;}
.side-sider .slick-slide div {width: 100% !important;}
.side-sider .slick-track {flex-direction: row; height: 100px; gap: 10px;}
.side-sider {display: none;}
}
@media only screen and (min-width: 970px) and (max-width: 1366px) {
    .ficed-top-nbar .BoxDiv{flex-direction: column;}
}
@media only screen and (min-width: 100px) and (max-width: 1024px) {
    .MobileNone {display: none;}
    ul.propertTabing {overflow-x: scroll;}
    ul.propertTabing li {white-space: pre;}
}
@media only screen and (min-width: 800px) and (max-width: 1600px) {
    .LoginHeading {margin-bottom: 20px;}
    .CustomContainer {bottom: 20px;}
}
.carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
    background-color: #e89532 !important;
}
.dateRangePicker{
    border: 1px solid #e89532;
    border-radius: 11px;
    background-color: #fff;
    padding: 4px 20px;
}
.activeBtn {
    background-color: #f28c28 !important;
    color: #fff !important;
    border: 1px solid #f28c28 !important;
}

.activeBtn:hover {
    background-color: #fff !important;
    color: #f28c28 !important;
    border: 1px solid #f28c28 !important;
}

.InactiveBtn {
    background-color: #fff !important;
    color: #f28c28 !important;
    border: 1px solid #f28c28 !important;
}

.InactiveBtn:hover {
    background-color: #f28c28 !important;
    color: #fff !important;
    border: 1px solid #f28c28 !important;
}