.selectedDate {
    background-color: #3498db;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.divider {
    border: none;
    border-top: 1px solid #000;
    width: 87%;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
}

.content {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
}

.subCategoryHeading {
    font-size: 17px;
    margin: 14px 0 3px;
}

.guests {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
}

.guestSubHeading {
    font-weight: 500;
}

.addSubcatgory {
    padding: 10px 11px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    margin-right: 5px;
    cursor: pointer;
    background-color: green;
}

.removeSubCategory {
    padding: 10px 11px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 15px;
    background-color: rgb(255 21 21 / 53%);
}

.removeButton {
    background-color: rgb(255 21 21 / 53%);
    color: #fff;
    font-size: 16px;
    margin-right: 5px;
    text-decoration: none;
    padding: 4px 20px;
    border-radius: 5px;
    border-color: transparent;
}

.category {
    max-height: 65vh;
    overflow-y: auto;
}

.outer-btn-div {
    position: relative;
}