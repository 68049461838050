.show-all {
    position: absolute;
    bottom: 13px;
    left: 15px;
    width: 96%;
    height: auto;
    background: rgba(250, 242, 233, .76);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    min-height: 137px;
    right: 0;
    border-radius: 10px;
    cursor: pointer;
}

.show-all p {
    font-weight: 600;
    margin: 0
}

.sub-cat-brrd h5 {
    font-size: 14px;
    padding: 7px 0px;
    font-weight: 400;
}

.sub-cat-brrd h6 {
    font-size: 16px;
}

.sub-cat-brrd ul li::before {
    position: absolute;
    top: 50%;
    left: -39px;
    width: 11px;
    height: 11px;
    background: #000;
    content: "";
    border-radius: 100px;
    transform: translateY(-50%);
}

.sub-cat-brrd ul li {
    list-style: none;
    position: relative;
    margin-bottom: 11px;
}

.sub-cat-brrd ul::before {
    position: absolute;
    width: 3px;
    height: 62%;
    content: "";
    background: black;
    left: -3px;
    top: 7px;
}

.sub-cat-brrd ul {
    padding-bottom: 0;
    position: relative;
    margin-bottom: 27px;
}

.sub-cat-brrd ul:last-child {
    border-bottom: 0px solid #ddd;
}

.no-data {
    height: 78vh;
}

.no-data i {
    font-size: 200px;
    margin-bottom: 20px;
    color: #e89532;
}

.product-slider-main h4 {
    color: #4e4439;
    /* font-family: Glitten; */
    font-size: 150%;
    font-weight: 600;
    margin: 13px 0 3px;
}

.pro-featu ul {
    display: flex;
    padding: 3px 0 18px;
    margin-bottom: 25px;
    /* border-bottom: 1px solid rgb(232, 151, 50); */
}

.pro-featu ul li {
    color: #4e4439;
    margin: 0;
    white-space: nowrap;
    font-family: Campton-light;
    list-style: none;
    position: relative;
    margin-right: 28px;
}

.pro-featu ul li::before {
    position: absolute;
    right: -16px;
    top: 9px;
    width: 3px;
    height: 3px;
    background: #404040;
    content: "";
    border-radius: 100px;
}

.pro-featu ul li:last-child::before {
    opacity: 0;
}
.side-sider img {
    width: 100%;
    border-radius: 11px;
    margin-bottom: 13px;
    height: 137px;
    object-fit: cover;
}
/* .side-sider div {
    display: flex;
    flex-direction: column;
    width: 100% !important;
} */
ul.car-amnites.aminity-wh-img {
    margin-bottom: 30px;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    grid-gap: 20px 25px;
    background: #f7f7f7;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    width: max-content;
    display: flex;
}
ul.car-amnites.aminity-wh-img.other-room {
    grid-template-columns: 1fr 1fr 1fr;
}
div.map iframe {
    width: 100%;
    border-radius: 20px;
    height: 300px;
}
ul.car-amnites.aminity-wh-img li {
    color: #333;
    font-family: Campton-light;
    font-weight: 700;
    margin: 0;
    position: relative;
    grid-gap: 10px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    white-space: nowrap;
    width: 18%;
}

ul.car-amnites li img {
    margin-right: 0px;
    max-width: 25px;
}
ul.car-amnites.aminity-wh-img li::before {
    display: none;
}
/* .side-sider  div {
    width: 100% !important;
} */
.slider-desc p {
    margin-bottom: 30px;
}
.slider-desc h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.side-sider {
    width: 20%;
    float: left;
    padding-left: 15px;
    position: relative;
}
.side-left {
    width: 85%;
    float: left;
    padding-right: 15px;
}
.side-left img {
    border-radius: 14px;
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.side-left ul {
    display: none !important;
}

@media (max-width:767px) {
    .show-all {
        bottom: 0px;
        left: 0px;
        width: 100%;
        min-height: 97px;
        right: 0;
        background: transparent;
    }

    .side-sider
    {
        display: none !important;
    }
    .pro-featu ul {
        flex-wrap: wrap;
    }
    ul.car-amnites.aminity-wh-img {
        width: 100%;
    }
    ul.car-amnites.aminity-wh-img li {
        font-size: 14px;
       
        width: 33%;
    }
    .slider-desc p strong {
        font-weight: 400;
        font-size: 15px;
    }
    .product-slider-main h4 {
        font-size: 19px;
    }
    .side-sider {
        margin-top: 10px;
    }
    .side-left {
        width: 100%;
        padding-right: 0;
        position: relative;
    }
    .side-left img {
        height: 300px;
    }
    .side-sider {
        width: 100%;
        padding-left: 0;
        display: flex;
    }
    .side-sider img {
        margin: 0 0px;
        height: 97px;
    }
    
    .show-all p {
        font-weight: 500;
        font-size: 13px;
    }

    .pro-featu ul li {
        margin-right: 13px;
        font-size: 14px;
        color: #1C2D34;
        font-weight: 600;
    }
    .pro-featu ul {
        padding: 3px 0 1px;
        margin-bottom: 0;
        border-bottom: 0;
    }
    .pro-featu ul li:last-child {
        margin: 0;
    }

    .pro-featu ul li::before {
        right: -9px;
    }
}