.image-preview {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border: 2px solid #f28c28;
    border-radius: 8px;
    overflow: hidden;
}

.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-btn {
    position: absolute;
    text-decoration: none;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropzone {
    width: 100px;
    height: 100px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #6c757d;
}

input[type="radio"] {
    height: 1em !important;
    width: 1em !important;
}

.CardColor {
    background-color: #E894321A;
    border-radius: 7px;
    /* max-height: 65px; */
    border: 2px solid white;
}

.CardColor small {
    font-weight: 300;
}

.activeBtn {
    background-color: #f28c28 !important;
    color: #fff !important;
    border: 1px solid #f28c28 !important;
}

.activeBtn:hover {
    background-color: #fff !important;
    color: #f28c28 !important;
    border: 1px solid #f28c28 !important;
}

.InactiveBtn {
    background-color: #fff !important;
    color: #f28c28 !important;
    border: 1px solid #f28c28 !important;
}

.disabledBtn {
background-color: #ddd !important;
    color: black !important;
    border: 1px solid #d9d9d9 !important;
}
.InactiveBtn:hover {
    background-color: #f28c28 !important;
    color: #fff !important;
    border: 1px solid #f28c28 !important;
}