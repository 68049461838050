.removeAll {
    cursor: pointer;
    color: white;
    border: none;
    padding: 6px 10px;
    clear: both;
    position: inherit;
    margin: 10px 0px;
    display: block;
    float: right;
}

.remove-images {
    margin-bottom: 0px;
    background-color: transparent !important;
    color: #fa4242 !important;
}

.main-img-priew {
    border-radius: 10px;
    height: 140px;
    margin-bottom: 6px;
    margin-right: 10px;
    padding: 5px;
    position: absolute;
    top: 27px;
    width: 140px;
}

.image-pre-outer .main-img-priew {
    position: relative;
    top: 0;
}

.main-img-priew img {
    border-radius: 6px;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.main-img-priew p {
    align-items: center;
    background: #e89532;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 25px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
}

.image-pre-outer {

    border-radius: 2px;
    padding: 6px 12px;
}

.selected-images {
    display: flex;
    width: 100%;
    border: 1px dashed #e0d6d6;
    flex-wrap: wrap;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader::before,
.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite;
}

.loader::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: #FF3D00;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    75%,
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
}