.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #00000087;
}

.content img {
    width: 100px;
    height: 100px;
}

.content {
    text-align: center;
    background-color: white;
    padding: 20px 35px;
    border-radius: 10px;
}

.content p {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 10px;
}