.main-img-priew p {
    position: absolute;
    top: 0;
    right: 0;
    background: #e89532;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.main-img-priew img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    min-width: 100%;
    border-radius: 6px;
    height: 100px;
    object-fit: cover;
}


.main-img-priew {
    position: absolute;
    width: 140px;
    /* border: 1px dashed #ddd; */
    padding: 5px;
    margin-right: 10px;
    border-radius: 10px;
    margin-bottom: 6px;
    height: 140px;
    top: 27px;
}


.main-img-priew {
    position: relative;
    top: 0;
}