/* Main container */
.AddteamOuter {
    padding: 20px;
    background: #f5f5f5;
    min-height: 100vh;
}

/* Top filter section */
.topFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.leftFilter {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    flex: 1;
}

.formGroup {
    position: relative;
    flex: 1;
    min-width: 200px;
}

.formGroup input,
.formGroup select,
.formGroup label {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 0 15px;
    font-size: 14px;
    background: #fff;
    color: #333;
    transition: all 0.3s ease;
}

.formGroup input:focus,
.formGroup select:focus {
    border-color: #E89432;
    outline: none;
    box-shadow: 0 0 0 2px rgba(232, 148, 50, 0.1);
}

.formGroup span {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
}

.formGroup input {
    padding-left: 42px;
}

.formGroup label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #666;
    cursor: pointer;
}

/* Search icon */
.formGroup span img {
    width: 16px;
    height: 16px;
}

/* Add New button */
.rightFilter button {
    background: #E89432;
    border: 0;
    color: #fff;
    border-radius: 8px;
    height: 50px;
    padding: 0 30px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 150px;
}

.rightFilter button:hover {
    background: #d67b1f;
    transform: translateY(-1px);
}

/* Table section */
.teamTable {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow-x: auto;
    margin-top: 20px;
}

.teamTable table {
    width: 100%;
    border-collapse: collapse;
}

.teamTable table tr th {
    background: #f8f9fa;
    padding: 18px 15px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.teamTable table tr td {
    padding: 18px 15px;
    font-size: 14px;
    color: #666;
    border-bottom: 1px solid #eee;
}

.teamTable table tr:hover td {
    background: #f8f9fa;
}

/* Status indicators */
.teamTable table tr td span.active {
    color: #2CAB2E;
    font-weight: 500;
}

.teamTable table tr td span.inactive {
    color: #FF2626;
    font-weight: 500;
}

/* Action buttons */
.teamTable table tr td span {
    margin-right: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.teamTable table tr td span:hover {
    opacity: 0.8;
}

.teamTable table tr td span img {
    width: 18px;
    height: 18px;
}

/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

.modal.show {
    opacity: 1;
    visibility: visible;
}

.modal-dialog {
    width: 100%;
    max-width: 450px;
    margin: 1rem auto;
    position: relative;
    transform: translateY(-20px);
    transition: transform 0.3s ease;
}

.modal.show .modal-dialog {
    transform: translateY(0);
}

.modal-content {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    overflow: hidden;
}

.modal-header {
    padding: 1rem 1.25rem;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f9fa;
}

.modal-header h5 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #1E2224;
}

.modal-header .btn-close {
    background: transparent;
    border: 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #666;
    opacity: 0.7;
    transition: all 0.2s ease;
    padding: 0.5rem;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.modal-header .btn-close:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.05);
}

.modal-body {
    padding: 1.5rem;
}

.employData {
    padding: 0;
}

.employData h4 {
    color: #1E2224;
    font-size: 20px;
    margin: 0 0 20px;
    font-weight: 600;
    text-align: center;
}

.employData .formGroup {
    margin-bottom: 16px;
    position: relative;
}

.employData .formGroup label {
    display: block;
    margin-bottom: 6px;
    color: #1E2224;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.employData .formGroup input,
.employData .formGroup select {
    width: 100%;
    height: 42px;
    border: 1.5px solid #eee;
    border-radius: 8px;
    padding: 0 12px;
    font-size: 13px;
    color: #333;
    transition: all 0.2s ease;
    background: #fff;
    font-family: 'Inter', sans-serif;
}

.employData .formGroup input:hover,
.employData .formGroup select:hover {
    border-color: #ddd;
}

.employData .formGroup input:focus,
.employData .formGroup select:focus {
    border-color: #E89432;
    outline: none;
    box-shadow: 0 0 0 3px rgba(232, 148, 50, 0.1);
}

.employData .formGroup select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    padding-right: 32px;
}

.employData .formGroup button {
    width: 100%;
    height: 42px;
    background: #E89432;
    color: #fff;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 8px;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}

.employData .formGroup button:hover {
    background: #d67b1f;
    transform: translateY(-1px);
    box-shadow: 0 3px 8px rgba(232, 148, 50, 0.2);
}

.employData .formGroup button:active {
    transform: translateY(0);
}

/* Error message styling */
.error {
    color: #FF2626;
    font-size: 11px;
    margin-top: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 3px;
}

.error::before {
    content: "!";
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background: #FF2626;
    color: #fff;
    border-radius: 50%;
    font-size: 9px;
    font-weight: bold;
}

/* DatePicker customization */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    height: 42px;
    border: 1.5px solid #eee;
    border-radius: 8px;
    padding: 0 12px;
    font-size: 13px;
    color: #333;
    transition: all 0.2s ease;
    background: #fff;
    font-family: 'Inter', sans-serif;
}

.react-datepicker__input-container input:hover {
    border-color: #ddd;
}

.react-datepicker__input-container input:focus {
    border-color: #E89432;
    outline: none;
    box-shadow: 0 0 0 3px rgba(232, 148, 50, 0.1);
}

/* Responsive styles */
@media (max-width: 768px) {
    .AddteamOuter {
        padding: 15px;
    }

    .topFilter {
        flex-direction: column;
        padding: 15px;
    }

    .leftFilter {
        width: 100%;
    }

    .formGroup {
        width: 100%;
    }

    .rightFilter button {
        width: 100%;
    }

    .teamTable {
        margin-top: 15px;
    }

    .teamTable table tr td span {
        margin-right: 10px;
    }

    .modal-dialog {
        max-width: 90%;
        margin: 0.75rem;
    }

    .modal-body {
        padding: 1.25rem;
    }

    .employData h4 {
        font-size: 18px;
        margin-bottom: 16px;
    }

    .employData .formGroup {
        margin-bottom: 14px;
    }
}

@media (max-width: 480px) {
    .AddteamOuter {
        padding: 10px;
    }

    .topFilter {
        padding: 10px;
    }

    .formGroup input,
    .formGroup select,
    .formGroup label {
        height: 45px;
    }

    .teamTable table tr th,
    .teamTable table tr td {
        padding: 12px 10px;
        font-size: 13px;
    }

    .employData {
        padding: 15px;
    }

    .employData h4 {
        font-size: 16px;
        margin-bottom: 14px;
    }

    .employData .formGroup input,
    .employData .formGroup select,
    .employData .formGroup button {
        height: 40px;
    }

    .modal-dialog {
        margin: 0.5rem;
    }

    .modal-body {
        padding: 1rem;
    }
}

/* Pagination styles */
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pagination button {
    background: #fff;
    border: 1px solid #ddd;
    color: #333;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination button:hover:not(:disabled) {
    background: #f8f9fa;
    border-color: #E89432;
    color: #E89432;
    transform: translateY(-1px);
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
}

.pageInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.pageInfo span {
    font-size: 14px;
    color: #666;
}

.totalCount {
    font-size: 12px;
    color: #999;
}

/* Loading state */
.loading {
    text-align: center;
    padding: 20px;
    color: #666;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.loading::after {
    content: "";
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #E89432;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Empty state */
.empty-state {
    text-align: center;
    padding: 40px 20px;
    color: #666;
    font-size: 14px;
}

.empty-state::before {
    content: "📭";
    display: block;
    font-size: 40px;
    margin-bottom: 10px;
}