.crop-container {
    position: relative;
    width: 50%;
    height: 400px;
    margin: 0 auto;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .crop-container .row {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .crop-container .col-12 {
    flex: 1; /* Ensures the Cropper takes up most of the space */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .crop-button {
    width: auto;
    padding: 15px ;
    font-size: 16px;
    background-color: #e89532;
    color: white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-align: center;
    position: absolute;
    bottom: 10px; /* Ensure the button sticks to the bottom */
  }
  
  .crop-button:hover {
    background-color: #e89532;
  }
  