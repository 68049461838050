.inner-tabs {
    position: relative;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-shadow.card-shadow01 {background: #F5EBFE;}
.card-shadow.card-shadow02 {background: #FFEEDC;}
.card-shadow.card-shadow03 {background: #e0eefe;}
.card-shadow.card-shadow04 {background: #F5EBFE;}
.card-shadow.card-shadow05 {background: #FDE8F3;}
.card-shadow.card-shadow06 {background: #e2eaf4;}

.card-shadow {
    padding: 20px;
    border-radius: 15px;
    display: table;
    width: 100%;
    height: 100%;
    background: #fff;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
    border: 1px solid transparent;
    transition-duration: .35s;
}
.table-chart-right.card-shadow {
    height: 100%;
    background: #f0f3f7;
}
.inner-tabs h4 {
    color: #282828;
    font-weight: bold;
    margin-bottom: 0;
}
.inner-tabs h5 {
    color: #2d2d2d;
    padding-right: 30px;
    text-transform: uppercase;
    font-size: 16px;
    padding-bottom: 10px;
    line-height: 24px;
    margin-bottom: 0;
}
.inner-tabs span i {font-size: 20px;}
.inner-tabs span {
    /* position: absolute;
    bottom: 0%;
    right: 20px; */
    width: 45px;
    height: 45px;
    background: #e89532;
    color: #fff;
    border-radius: 100px;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}   
.card-shadow:hover {
   border-color: #e89532;
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
 .cenetr-shadow.inner-tabs.card-shadow {
    background: #FFECD5;
}
.proerty-tabs a {
    text-decoration: none;
    height: 100%;
    display: block;
}
@media (max-width:767px)
{
    .card-shadow {
        margin: 7px 0;
    }
    .proerty-tabs {
        margin: 0px !important;
    }
}

.proerty-tabs .row {row-gap: 20px;}

/* Dashboard Card Styles */
.dashboard-row {
    margin: 0 -15px;
}

.card-link {
    display: block;
    text-decoration: none;
    color: inherit;
    height: 100%;
}

.dashboard-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-icon {
    margin-bottom: 15px;
    display: inline-block;
}

.card-title {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 16px;
}

.total-amount {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
}

.revenue-details {
    margin-top: auto;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 15px;
}

.revenue-detail-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 3px 0;
}

.revenue-label {
    font-size: 13px;
    color: #666;
}

.revenue-value {
    font-size: 13px;
    font-weight: 600;
    color: #333;
}

/* @mediaScreen */
@media only screen and (min-width: 100px) and (max-width: 800px) {   
    .inner-tabs h5 {font-size: 16px;} 
    .inner-tabs {margin-bottom: 10px;}
    .card-shadow {padding: 15px;}
    body .proerty-tabs {margin-bottom: 10px !important;}
    .proerty-tabs {padding: 15px;}
}
@media only screen and (min-width: 800px) and (max-width: 1140px) { 
    .card-shadow {padding: 15px;}
}