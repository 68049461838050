.side-bar {
    height: 100vh;
    background: #fff;
    box-shadow: 0 0 40px rgb(66 66 66 / 10%);
    overflow-y: auto;
    border-radius: 0;
}
.side-bar ul {
    padding: 0;
    margin: 40px 0 0;
}
.side-bar ul li {
    line-height: normal;
    text-align: left;
    padding: 8px 30px 0px 30px;
    white-space: wrap;
}

.side-bar ul li.down-arow {
    padding: 24px 20px 15px 45px;
}

.side-bar ul li a {
    color: #141A1E;
    text-decoration: none;
    transition: 0.5s;
    width: 100%;
    display: table;
    padding: 12px 15px;
    border-radius: 6px;
}
.side-bar ul li, .side-bar ul li a {
    font-size: 14px; text-transform: uppercase; font-weight: 500;
}

.side-bar ul li:hover a {
    color: #e89532;
    font-weight: 600;
    background: #e8953240;
}
.side-bar ul li.active a i {
    color: #fff;
}
.side-bar ul li:hover a i {
    color: #e89532;
}
.side-bar ul li.down-arow ul li:hover a, .side-bar ul li.down-arow:hover a {
    background: transparent;
    color: #141A1E;
    font-weight: 400;
}
.side-bar ul li i {
    margin-right: 15px;
    font-size: 18px;
    color: #BEC3D6;
}

/* .app.active .left-sidebar {
    width: 7%;
    transition: 0.5s;
} 
.app.active .left-sidebar ul li.active a i {
    margin: 0;
}

.side-bar ul li.active a {
    color: #e89532;
    font-weight: 600;
    background: #e8953240;
}
.app.active .logo img {
    max-width: 98px;
    transition: 0.5s;
}
 .active .left-sidebar a {
    font-size: 0;
    transition: 0.5s;
}
.active .left-sidebar li.down-arow {
    font-size: 0;
} */
.side-bar ul li:last-child {
    border-bottom: 0;
}
.side-bar ul.submenu {
    margin: 0;
}

.side-bar ul.submenu li {
    padding: 0 20px 0;
    list-style: none;
}
.side-bar ul.submenu li a {
    padding-left: 16px;
}
.logo img {
    max-width: 150px;
    transition: 0.5s;
}
.logo {
    text-align: center;
    padding-top: 31px;
    padding-bottom: 19px;
}
span.side-br-mobile {
    display: none;
}
.side-bar ul.submenu i {
    display: none;
}

li.down-arow {
    position: relative;
}
li.down-arow:before {
    border: 2px solid #888;
    border-left: 0;
    border-top: 0;
    content: "";
    height: 10px;
    position: absolute;
    right: 23px;
    top: 32px;
    transform: rotate(-45deg);
    width: 10px;
}
.left-sidebar {
    width: 20%;
    height: 100%;
    transition: 0.5s;
}
.right-sidebar {
    width: 100%;
    transition: 0.5s;
    padding-left: 0;
    padding-right: 0px;
    padding-top: 13px;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 20px;
    background-color: #f4f4f487;
    padding: 20px 50px 20px 50px;
}
/* .app.active .right-sidebar {
    width: 90%;
    transition: 0.5s;
} */

.side-bar ul li.active a {
    color: #fff;
    font-weight: 600;
    background: #e89532;
}
.side-bar ul li ul.submenu li a {
    color: #141A1E;
    font-weight: 500;
    background: transparent;
    padding-bottom: 0;
}
@media (max-width:1600px)
{
    .side-bar ul li {
        padding: 8px 20px 0px 20px;
    }
}

@media (max-width:1400px)
{
    .side-bar ul li i {
        margin-right: 8px;
        font-size: 16px;
    }
    .side-bar ul li.down-arow {
        padding: 24px 20px 15px 35px;
    }
    .side-bar ul li {
        padding: 8px 20px 0px 20px;
    }
    .app.active .logo img {
        max-width: 62px;
    }
    /* .right-sidebar {
        width: 80%;
    } */
    .side-bar ul li a {
        font-size: 14px;
    }
    .side-bar ul li {
        font-size: 14px;
    }
    /* .left-sidebar {
        width: 24%;
    } */
    li.down-arow::before {
        width: 8px;
        height: 8px;
        right: 9px;
        top: 34px;
    }
}
@media (max-width:991px)
{
    /* .left-sidebar {
        width: 39%;
        position: fixed;
        left: 0;
        z-index: 1;
        top: 0;
    }
    .right-sidebar {
        width: 100%;
    } */
    span.side-br-mobile {
        display: block;
      }
      .logo {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 21px 0;
    }
    span.side-br-mobile {
        color: #e89532;
        font-size: 22px;
    }
}
@media(max-width:767px)
{
    .side-bar {
        overflow-y: inherit !important;
    }
    .side-bar ul {
        padding: 0;
        margin: 20px 0 0;
        /* overflow-y: scroll; */
        height: 100%;
        /* padding-bottom: 11rem; */
    }
    .left-sidebar {
        width: 100%;
    }
    .right-sidebar {
        padding-left: 8px;
        padding-right: 10px;
        height: auto;
        overflow: initial;
    }
    .app.active .right-sidebar {
        width: 100%;
    }
    .side-bar ul li {
        padding: 8px 50px 0px 16px;
    }
    .right-sidebar {
        padding-top: 0;
    }
    .side-bar ul li a {
        padding: 9px 15px;
    }
    .side-bar ul li.down-arow {
        padding: 24px 20px 15px 35px;
    }
    li.down-arow::before {
        right: 19px;
    }   
    /* .app.active .left-sidebar {
        width: 0;
    } */
    .right-sidebar {
        padding-bottom: 80px;
    }
}

/* .active .right-sidebar {width: 100%;} */
.active .left-sidebar {display: none ;} 
/* body .left-sidebar {display: block !important;} */

h4.TopTitle {font-size: 20px; color: #ed8436; font-weight: 600; text-transform: uppercase;}
/* @mediaScreen */
@media only screen and (min-width: 100px) and (max-width: 1280px) {
    .right-sidebar {width: 100% !important;}
}
@media only screen and (min-width: 767px) and (max-width: 980px) {
    .left-sidebar {margin-left: -300px }
    .right-sidebar {width: 100%; margin-left: 120px;}

    .app.active .left-sidebar {margin-left: 0px; display: block;}
    .app.active .right-sidebar {width: 100%; margin-left: 0px;}
}
@media only screen and (min-width: 100px) and (max-width: 767px) {
    .left-sidebar {margin-top: 100vh; position: fixed; z-index: 11111;}
    .right-sidebar {width: 100%; margin-left: 0px;}   
    .app.active .left-sidebar {margin-top: 10% !important; display: block;
    width: 100%; z-index: 111; border-radius: 30px; left: 0; right: 0;}
    .app.active .logo img {max-width: 110px;}    
}
@media only screen and (min-width: 1400px) and (max-width: 1601px) {
    .side-bar ul li.down-arow {
        padding: 24px 20px 15px 35px !important;
    }
}
@media only screen and (min-width: 981px) and (max-width: 1140px) {
    .left-sidebar {width: 30%;}
}
@media only screen and (min-width: 1140px) and (max-width: 1500px) {
    .left-sidebar {width: 25%;}
}


